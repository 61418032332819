import React from "react";
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import pushPng from "../../Assets/Push notification.png";
import { ReactComponent as DotsIcon } from "../../Assets/dots.svg";

const AllNotification = () => {

    return (
        <>
            <Box sx={{
                pt: 1,
                backgroundColor: "#212330",
                px: 1,
                height: '87vh',
                borderRadius: "10px",
            }}>
                <Typography variant="body2" sx={{ mt: 1, fontSize: "12px", color: "#BEC0C9", textAlign: "center" }}>
                    The central hub for all immediate and important activity alerts.
                </Typography>
                <List>
                    <ListItem button>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={pushPng}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant="body2" sx={{fontSize:"13px", color:"#BEC0C9"}}><b>Ashwin Bose</b> is offering help. Do you want to share your location with him?</Typography>}
                            style={{ fontSize: "16px" }}
                        />
                        <IconButton component="span" sx={{ color: "#fff" }}>
                            <DotsIcon width="16px" height="16px" />
                        </IconButton>
                    </ListItem>
                </List>
            </Box>
        </>
    );
};

export default AllNotification;
