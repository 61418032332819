import React from "react";
import { Grid } from "@mui/material";
import ChatList from "./ChatList";
import axios from "axios";
import { useNavigate, Outlet, useOutletContext } from "react-router-dom";
import NavBar from "./NavBar";

const Chats = () => {
  const navigate = useNavigate();
  const { user } = useOutletContext();

  const handleSelectChat = async (data) => {
    if (data.id && data.verified) {
      const url = `https://api.locaided.com/v1/users/${data.id}/direct_message`;
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      const formData = new FormData();
      formData.append("description", "Hey! What’s up? 😄 Let’s chat!");

      try {
        const response = await axios.post(url, formData, {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        });
        const selectedChatData = response.data.data;
        localStorage.setItem("selectedChat", JSON.stringify(selectedChatData));
        navigate(`private/${data.id}`);
      } catch (error) {
        console.error("Error sending message:", error.response ? error.response.data : error.message);
      }
    } else if (data.id && data.opposite_user_full_name) {
      localStorage.setItem("selectedChat", JSON.stringify(data));
      navigate(`private/${data.user_id}`);
    } else if (data.group) {
      localStorage.setItem("selectedChat", JSON.stringify(data));
      navigate(`group_chat/${data.group.id}`);
    } else {
      console.error("Unknown data format");
    }
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <NavBar user={user} />
      <Grid item xs={12} md={7}>
        <Outlet context={{ onSelectChat: handleSelectChat }} />
      </Grid>
      <Grid item xs={12} md={3} pl={0}>
        <ChatList onSelectChat={handleSelectChat} />
      </Grid>
    </Grid>
  );
};

export default Chats;
