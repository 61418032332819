import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Avatar,
  List,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as WalletSVG } from "../../Assets/wallet.svg";

const BalanceSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#212330",
        padding: "1rem",
        borderRadius: "10px",
        width: "350px",
        color: "#fff",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <WalletSVG width="20px" height="18px" />
          <Typography variant="body2" color="#D7D9E3">EYS Balance</Typography>
          <Box
            sx={{
              backgroundColor: "#0FCD39",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></Box>
        </Box>
        <Typography variant="contain" color="#F0F1F5" fontFamily="urbanist">
          584 Coins
        </Typography>
      </Box>

      <Button variant="body2" style={{ color: "#F0F1F5", fontSize: "0.75rem" }}>
        Buy coins
      </Button>
    </Box>
  );
};

const ChatList = ({ onSelectChat }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchChats = () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get("https://api.locaided.com/v1/users/get_all_chats?type=direct", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setChats(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chats:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchChats();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={{
      width: 350,
      height: '100vh',
      ml: 1,
      color: '#fff',
    }}>
      <BalanceSection />
      <Box sx={{
        mt: 1,
        backgroundColor: "#212330",
        px: "1rem",
        borderRadius: "10px",
      }}>
        <h4 style={{ padding: "0.5rem 0", margin:0 }}>Messages</h4>
        <Divider />
        <List>
          {chats.map((chat, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onSelectChat(chat.message)}
            >
              <ListItemAvatar>
                <Avatar
                  alt={chat.message.opposite_user_full_name}
                  src={chat.message.opposite_user_profile_image.sharable}
                  style={{width: "36px", height: "36px",}}
                />
              </ListItemAvatar>
              <ListItemText primary={chat.message.opposite_user_full_name} style={{fontSize:"16px"}}/>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ChatList;
