/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    Typography,
    IconButton,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    ListItemAvatar,
    Grid,
    Popover,
    FormControlLabel,
    Radio,
    InputAdornment,
    InputBase,
    Paper,
    CircularProgress,
    Divider,
    Modal,
    Slider,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Cropper from "react-easy-crop";
import { ReactComponent as ArrowIcon } from "../../../Assets/Arrow.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/delete-03.svg";
import { ReactComponent as CloseIcon } from "../../../Assets/log-out.svg";
import { ReactComponent as ReportIcon } from "../../../Assets/alert-circle.svg";
import { ReactComponent as UploadIcon } from "../../../Assets/image-upload.svg";
import { ReactComponent as MuteIcon } from "../../../Assets/mute.svg";
import { ReactComponent as PrivateIcon } from "../../../Assets/square-lock.svg";
import { ReactComponent as PublicIcon } from "../../../Assets/square-unlock.svg";
import { ReactComponent as SlowIcon } from "../../../Assets/stop-watch.svg";
import { ReactComponent as SlowDarkIcon } from "../../../Assets/stop-watch-2.svg";
import { ReactComponent as FilterIcon } from "../../../Assets/filter-2.svg";
import { ReactComponent as AddIcon } from "../../../Assets/add.svg";
import { ReactComponent as DotsIcon } from "../../../Assets/dots.svg";
import { ReactComponent as LogoutIcon } from "../../../Assets/logout.svg";
import { ReactComponent as CopyIcon } from "../../../Assets/copy.svg";
import { ReactComponent as SearchIcon } from "../../../Assets/search.svg";

const GroupSetting = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [anchorElMute, setAnchorElMute] = useState(null);
    const [anchorElSlow, setAnchorElSlow] = useState(null);
    const isMuteOpen = Boolean(anchorElMute);
    const isSlowOpen = Boolean(anchorElSlow);
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reqLoading, setReqLoading] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [leaveId, setLeaveId] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [closeChat, setCloseChat] = useState(false);
    const [leaveChat, setLeaveChat] = useState(false);
    const [reportChat, setReportChat] = useState(false);
    const [editType, setEditType] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);

    useEffect(() => {
        const savedChat = localStorage.getItem("selectedChat");
        if (savedChat) {
            setSelectedChat(JSON.parse(savedChat));
        }
    }, []);
    console.log(selectedChat)

    const handleImageChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setEditType(type);
            setOpen(true);
        }
    };

    const handleZoomChange = (event, newValue) => {
        setZoom(newValue);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const getCroppedImage = async (imageSrc, croppedAreaPixels) => {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.src = imageSrc;
        const { width, height } = croppedAreaPixels;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        img.onload = () => {
            ctx.drawImage(
                img,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                croppedAreaPixels.width,
                croppedAreaPixels.height
            );
        };

        return new Promise((resolve) => {
            img.onload = () => {
                ctx.drawImage(
                    img,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );
                resolve(canvas.toDataURL());
            };
        });
    };

    const handleApply = async () => {
        const croppedImage = await getCroppedImage(selectedImage, croppedAreaPixels);
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        if (editType === "profile") {
            setProfileImage(blob);
        } else if (editType === "banner") {
            setBannerImage(blob);
        }
        setOpen(false);
    };

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setCloseChat(true);
    };

    const handleLeaveOpen = (id) => {
        setLeaveId(id);
        setLeaveChat(true);
    };

    const handleClickReport = (id) => {
        setReportId(id);
        setReportChat(true);
    };

    const handleDelete = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/groups/${deleteId}`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            navigate("/dashboard/chats/group");
            handleClose();
            console.log(response, "success");
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const handleLeave = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/groups/${leaveId}leave`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            navigate("/dashboard/chats/group");
            handleClose();
            console.log(response, "success");
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const handleReport = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/groups/${reportId}report`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            navigate("/dashboard/chats/group");
            handleClose();
            console.log(response, "success");
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const fetchMembers = () => {
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        axios
            .get(`https://api.locaided.com/v1/groups/${id}/get_members`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            })
            .then((response) => {
                const groupMembers = response.data.data.group_members;
                const isAdminUser = selectedChat?.user?.id
                    ? groupMembers.some(
                        (member) => member.admin === true && member.id === selectedChat.user.id
                    )
                    : false;
                setMembers(groupMembers);
                setIsAdmin(isAdminUser);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching Member:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchMembers();
    }, []);

    const handleOpenMutePopup = (event) => {
        setAnchorElMute(event.currentTarget);
    };

    const handleOpenSlowPopup = (event) => {
        setAnchorElSlow(event.currentTarget);
    };

    const handleUpdate = async () => {
        setReqLoading(true)
        const url = `https://api.locaided.com/v1/groups/${selectedChat.group.id}`;
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        const formData = new FormData();
        if (profileImage) {
            formData.append("group_image", profileImage, "profile.jpg");
        }
        if (bannerImage) {
            formData.append("group_banner", bannerImage, "banner.jpg");
        }

        try {
            const response = await axios.put(url, formData, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response.data.message);
            setProfileImage(null);
            setBannerImage(null);
            setReqLoading(false);
        } catch (error) {
            console.error(
                "Error sending group creation data:",
                error.response ? error.response.data : error.message
            );
        }
    };

    const handleClose = () => {
        setCloseChat(false);
        setLeaveChat(false);
        setReportChat(false);
        setDeleteId(null);
        setLeaveId(null);
        setReportId(null);
        setAnchorElMute(null);
        setAnchorElSlow(null);
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FD445D" }} />
                <Typography variant="h6" style={{ marginTop: "18px" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <>
            <input
                type="file"
                accept="image/*"
                id="profile-upload"
                onChange={(e) => handleImageChange(e, "profile")}
                style={{ display: "none" }}
            />
            <input
                type="file"
                accept="image/*"
                id="banner-upload"
                onChange={(e) => handleImageChange(e, "banner")}
                style={{ display: "none" }}
            />
            <Box sx={{ minHeight: "88vh", display: "flex", flexDirection: "column", pb: 2, backgroundColor: "#212330", borderRadius: "12px" }}>
                <Box display="flex" alignItems="center" p={2} gap={2}>
                    <IconButton component="span" sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
                        <ArrowIcon width="16px" height="16px" />
                    </IconButton>
                    <Typography variant="body1" fontWeight="bold"> Settings</Typography>
                </Box>
                <Box display="flex" alignItems="center" px={3} py={2} justifyContent="space-around" >
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: "center",
                                color: "#BEC0C9",
                                fontWeight: "bold",
                                fontSize: "12px",
                            }}
                        >
                            Group Picture
                        </Typography>
                        {
                            profileImage ? (
                                <Box
                                    sx={{
                                        position: "relative",
                                        width: 110,
                                        height: 110,
                                    }}
                                >
                                    <Avatar
                                        src={URL.createObjectURL(profileImage)}
                                        alt="Profile"
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            cursor: "pointer",
                                            mt: 1,
                                        }}
                                        onClick={() => document.getElementById("profile-upload").click()}
                                    />
                                    <IconButton
                                        component="span"
                                        onClick={() => document.getElementById("profile-upload").click()}
                                        sx={{
                                            position: "absolute",
                                            bottom: -8,
                                            right: 0,
                                            p: 5.3,
                                            color: "#fff",
                                            "&:hover": {
                                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                            },
                                        }}
                                    >
                                        <UploadIcon width="25px" height="25px" />
                                    </IconButton>
                                </Box>
                            ) : selectedChat.group.group_image_url.sharable ? (
                                <Box
                                    sx={{
                                        position: "relative",
                                        width: 110,
                                        height: 110,
                                    }}
                                >
                                    <Avatar
                                        src={selectedChat.group.group_image_url.sharable}
                                        alt="Profile"
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            cursor: "pointer",
                                            mt: 1,
                                        }}
                                        onClick={() => document.getElementById("profile-upload").click()}
                                    />
                                    <IconButton
                                        component="span"
                                        onClick={() => document.getElementById("profile-upload").click()}
                                        sx={{
                                            position: "absolute",
                                            bottom: -8,
                                            right: 0,
                                            p: 5.3,
                                            color: "#fff",
                                            "&:hover": {
                                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                            },
                                        }}
                                    >
                                        <UploadIcon width="25px" height="25px" />
                                    </IconButton>
                                </Box>
                            ) : (
                                <IconButton
                                    component="span"
                                    onClick={() => document.getElementById("profile-upload").click()}
                                    sx={{
                                        mt: 1,
                                        p: 5,
                                        backgroundColor: "#303343",
                                    }}
                                >
                                    <UploadIcon width="26px" height="26px" />
                                </IconButton>
                            )
                        }
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="left">
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "start",
                                    color: "#BEC0C9",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                }}
                            >
                                Group Chat Banner
                            </Typography>
                            <Button variant="contained" size="small"
                                onClick={handleUpdate}
                                disabled={reqLoading || (!profileImage && !bannerImage)}
                                sx={{
                                    backgroundColor: "#FF2557",
                                    fontSize: "12px",
                                    borderRadius: "100px",
                                    color: "#FFF",
                                    "&:hover": {
                                        backgroundColor: "#FF2557",
                                    },
                                    "&.Mui-disabled": {
                                        color: "#000",
                                        backgroundColor: "#fff",
                                    },
                                }}>
                                {reqLoading ? (
                                    <CircularProgress size={20} sx={{ color: "#FFF" }} />
                                ) : (
                                    <b>Save Changes</b>
                                )}
                            </Button>
                        </Box>
                        {bannerImage ? (
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "100%",
                                    height: "110px",
                                    mt: 1,
                                }}
                            >
                                <img
                                    src={URL.createObjectURL(bannerImage)}
                                    alt="Banner"
                                    style={{
                                        width: "105%",
                                        height: "100%",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => document.getElementById("banner-upload").click()}
                                />
                                <Button
                                    onClick={() => document.getElementById("banner-upload").click()}
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: -28,
                                        py: 5.3,
                                        px: 34.5,
                                        borderRadius: "10px",
                                        backgroundColor: "transparent",
                                        color: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                                        },
                                    }}
                                >
                                    <UploadIcon width="26px" height="26px" />
                                </Button>
                            </Box>
                        ) : selectedChat.group.group_banner_url.sharable ? (
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "100%",
                                    height: "110px",
                                    mt: 1,
                                }}
                            >
                                <img
                                    src={selectedChat.group.group_banner_url.sharable}
                                    alt="Banner"
                                    style={{
                                        width: "105%",
                                        height: "100%",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => document.getElementById("banner-upload").click()}
                                />
                                <Button
                                    onClick={() => document.getElementById("banner-upload").click()}
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: -28,
                                        py: 5.3,
                                        px: 34.5,
                                        borderRadius: "10px",
                                        backgroundColor: "transparent",
                                        color: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                                        },
                                    }}
                                >
                                    <UploadIcon width="26px" height="26px" />
                                </Button>
                            </Box>
                        ) : (
                            <Button
                                onClick={() => document.getElementById("banner-upload").click()}
                                sx={{
                                    mt: 1,
                                    py: 5,
                                    px: 36,
                                    borderRadius: "10px",
                                    backgroundColor: "#303343",
                                }}
                            >
                                <UploadIcon width="26px" height="26px" />
                            </Button>
                        )}
                    </Box>
                </Box>
                <Grid container justifyContent="space-between" px={2} gap={1}>
                    <Grid item md={4} display="flex" alignItems="center" gap={1} bgcolor="#303343" px={2} borderRadius="10px">
                        <Avatar
                            src={selectedChat.group.group_image_url.sharable}
                            alt="Profile"
                            sx={{
                                width: 45,
                                height: 45,
                            }}
                        />
                        <Typography variant="body2" sx={{ fontSize: "13px", fontWeight: "600" }}>
                            {selectedChat.group.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={1.8}>
                        <Button
                            onClick={() => console.log(`clicked`)}
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#303343",
                                color: "#BEC0C9",
                                borderRadius: "10px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                },
                            }}
                        >
                            <MuteIcon width="22px" height="22px" />
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#BEC0C9",
                                    marginTop: 0.5,
                                    textAlign: "center",
                                }}
                            >
                                Mute
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={1.8}>
                        {selectedChat.group.public ? (
                            <Button
                                onClick={() => console.log(`clicked`)}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#303343",
                                    color: "#BEC0C9",
                                    borderRadius: "10px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    },
                                }}
                            >
                                <PublicIcon width="22px" height="22px" />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#BEC0C9",
                                        marginTop: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Public
                                </Typography>
                            </Button>
                        ) : (
                            <Button
                                onClick={() => console.log(`clicked`)}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#303343",
                                    color: "#BEC0C9",
                                    borderRadius: "10px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    },
                                }}
                            >
                                <PrivateIcon width="22px" height="22px" />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#BEC0C9",
                                        marginTop: 0.5,
                                        textAlign: "center",
                                    }}
                                >
                                    Private
                                </Typography>
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={1.8}>
                        <Button
                            onClick={handleOpenSlowPopup}
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#303343",
                                color: "#BEC0C9",
                                borderRadius: "10px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                },
                            }}
                        >
                            <SlowIcon width="22px" height="22px" />
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#BEC0C9",
                                    marginTop: 0.5,
                                    textAlign: "center",
                                }}
                            >
                                Slow Mode
                            </Typography>
                        </Button>
                        <Popover
                            open={isSlowOpen}
                            anchorEl={anchorElSlow}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: 5,
                                horizontal: 150,
                            }}
                            sx={{
                                "& .MuiPaper-root": {
                                    borderRadius: "10px",
                                },
                            }}
                        >
                            <Box p={2} sx={{ width: 235, bgcolor: "#F9F9F9", color: "#000", borderRadius: "10px" }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" fontSize="15px" fontWeight={600}>
                                        Enable Slow Mode
                                    </Typography>
                                    <SlowDarkIcon width="24px" height="24px" />
                                </Box>
                                <Typography variant="body2" mt={1} fontSize="12px" fontFamily="Helvetica">
                                    The “Slow Mode” feature allows the admin to set a cooldown timer between messages when chat traffic is heavy. This helps manage the flow of messages and ensures everyone can participate without overwhelming the chat.
                                </Typography>
                                <List
                                    sx={{
                                        padding: 0,
                                    }}
                                >
                                    <Divider
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "#E0E0E0",
                                            height: "1px",
                                        }}
                                    />
                                    <ListItem
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <ListItemText
                                            primary="5 Seconds"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        />
                                        <FormControlLabel
                                            sx={{
                                                margin: 0,
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            borderRadius: "50%",
                                                            color: "#BDBDBD",
                                                        },
                                                        "&.Mui-checked": {
                                                            color: "#FF2557",
                                                        },
                                                        "&.Mui-checked .MuiSvgIcon-root": {
                                                            color: "#FF2557",
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#E0E0E0",
                                            height: "1px",
                                        }}
                                    />
                                    <ListItem
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <ListItemText
                                            primary="15 Seconds"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        />
                                        <FormControlLabel
                                            sx={{
                                                margin: 0,
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            borderRadius: "50%",
                                                            color: "#BDBDBD",
                                                        },
                                                        "&.Mui-checked": {
                                                            color: "#FF2557",
                                                        },
                                                        "&.Mui-checked .MuiSvgIcon-root": {
                                                            color: "#FF2557",
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#E0E0E0",
                                            height: "1px",
                                        }}
                                    />
                                    <ListItem
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <ListItemText
                                            primary="30 Seconds"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        />
                                        <FormControlLabel
                                            sx={{
                                                margin: 0,
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            borderRadius: "50%",
                                                            color: "#BDBDBD",
                                                        },
                                                        "&.Mui-checked": {
                                                            color: "#FF2557",
                                                        },
                                                        "&.Mui-checked .MuiSvgIcon-root": {
                                                            color: "#FF2557",
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#E0E0E0",
                                            height: "1px",
                                        }}
                                    />
                                    <ListItem
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <ListItemText
                                            primary="60 Seconds"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        />
                                        <FormControlLabel
                                            sx={{
                                                margin: 0,
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            borderRadius: "50%",
                                                            color: "#BDBDBD",
                                                        },
                                                        "&.Mui-checked": {
                                                            color: "#FF2557",
                                                        },
                                                        "&.Mui-checked .MuiSvgIcon-root": {
                                                            color: "#FF2557",
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#E0E0E0",
                                            height: "1px",
                                        }}
                                    />
                                    <ListItem
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <ListItemText
                                            primary="None"
                                            sx={{
                                                fontSize: "16px",
                                            }}
                                        />
                                        <FormControlLabel
                                            sx={{
                                                margin: 0,
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            borderRadius: "50%",
                                                            color: "#BDBDBD",
                                                        },
                                                        "&.Mui-checked": {
                                                            color: "#FF2557",
                                                        },
                                                        "&.Mui-checked .MuiSvgIcon-root": {
                                                            color: "#FF2557",
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </Popover>
                    </Grid>
                    <Grid item xs={1.8}>
                        <Button
                            onClick={handleOpenMutePopup}
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#303343",
                                color: "#BEC0C9",
                                borderRadius: "10px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                },
                            }}
                        >
                            <FilterIcon width="22px" height="22px" />
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#BEC0C9",
                                    marginTop: 0.5,
                                    textAlign: "center",
                                }}
                            >
                                Muted Words
                            </Typography>
                        </Button>
                        <Popover
                            open={isMuteOpen}
                            anchorEl={anchorElMute}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: 5,
                                horizontal: 380,
                            }}
                            sx={{
                                "& .MuiPaper-root": {
                                    borderRadius: "15px",
                                },
                            }}
                        >
                            <Box p={2} sx={{ width: 460, bgcolor: "#303343", color: "#BEC0C9", borderRadius: "15px" }}>
                                <Box display="flex" alignItems="center" width="100%">
                                    <IconButton component="span" sx={{ color: "#fff" }} onClick={handleClose}>
                                        <ArrowIcon width="16px" height="16px" />
                                    </IconButton>
                                    <Typography variant="body2" textAlign="center" fontWeight="bold"> Mute Words</Typography>
                                </Box>
                                <Paper
                                    style={{
                                        padding: "2px 4px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        margin: "10px auto",
                                        borderRadius: "25px",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <InputBase
                                        style={{ marginLeft: 8, flex: 1, color: "#616161" }}
                                        placeholder="Search Words"
                                        inputProps={{ "aria-label": "search" }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </Paper>
                                <Typography variant="body2" fontSize="11px">
                                    This feature allows you to block specific keywords from appearing in your chat. Simply add the words you wish to avoid, and any messages containing these terms will be filtered out, helping maintain a cleaner and more relevant conversation stream.
                                </Typography>
                            </Box>
                        </Popover>
                    </Grid>
                </Grid>
                {isAdmin ? (
                    <Grid container justifyContent="space-between" px={2} mt={2} gap={1}>
                        <Grid item xs={1.8}>
                            <Button
                                onClick={() => console.log(`clicked`)}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                    backgroundColor: "#303343",
                                    color: "#BEC0C9",
                                    borderRadius: "10px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    },
                                }}
                            >
                                <PrivateIcon width="24px" height="24px" />
                                <Typography variant="body2" mt={0.25}>
                                    Private
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={1.8} display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="#303343" borderRadius="10px">
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#BEC0C9",
                                }}
                            >
                                Link expires
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#BEC0C9",
                                }}
                            >
                                29:58
                            </Typography>
                        </Grid>
                        <Grid item xs={7.8}>
                            <Button
                                onClick={() => console.log(`clicked`)}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "#303343",
                                    color: "#BEC0C9",
                                    borderRadius: "10px",
                                    px: 2,
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    },
                                }}
                            >
                                <Typography variant="body2" sx={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    https://live.locaided.app/scrvtdafguerdf
                                </Typography>
                                <CopyIcon width="24px" height="24px" />
                            </Button>
                        </Grid>
                    </Grid>
                ) : ""}
                {isAdmin ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        m={2}
                        gap={2}
                        sx={{ backgroundColor: "#303343", borderRadius: "10px" }}
                    >
                        <List
                            sx={{
                                borderRadius: "10px",
                                backgroundColor: "#303343",
                                color: "#D7D9E3",
                            }}
                        >
                            <ListItem button onClick={handleClickReport}>
                                <ListItemIcon>
                                    <DeleteIcon width="20px" height="20px" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Empty Chat"
                                    primaryTypographyProps={{
                                        style: { fontSize: "14px" },
                                    }}
                                />
                            </ListItem>
                            <ListItem button onClick={() => handleClickOpen(selectedChat.group.id)}>
                                <ListItemIcon>
                                    <LogoutIcon width="20px" height="20px" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Close and Delete Chat"
                                    primaryTypographyProps={{
                                        style: { fontSize: "14px", color: "#FF2557" },
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        m={2}
                        gap={2}
                        sx={{ backgroundColor: "#303343", borderRadius: "10px" }}
                    >
                        <List
                            sx={{
                                borderRadius: "10px",
                                backgroundColor: "#303343",
                                color: "#D7D9E3",
                            }}
                        >
                            <ListItem button onClick={() => console.log(`Copy Link for message ID:`)}>
                                <ListItemIcon>
                                    <DeleteIcon width="18px" height="18px" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Empty Chat"
                                    primaryTypographyProps={{
                                        style: { fontSize: "14px" },
                                    }}
                                />
                            </ListItem>
                            <ListItem button onClick={() => handleLeaveOpen(selectedChat.group.id)}>
                                <ListItemIcon>
                                    <CloseIcon width="18px" height="18px" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Leave and Delete Chat"
                                    primaryTypographyProps={{
                                        style: { fontSize: "14px" },
                                    }}
                                />
                            </ListItem>
                            <ListItem button onClick={() => handleClickReport(selectedChat.group.id)}>
                                <ListItemIcon>
                                    <ReportIcon width="18px" height="18px" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Report this Group Chat"
                                    primaryTypographyProps={{
                                        style: { fontSize: "14px", color: "#FF2557" },
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Box>
                )}
                <Box px={2} gap={1} borderRadius="10px">
                    <span style={{ fontSize: "14px", fontFamily: "Helvetica", color: "#616161", marginLeft: "1rem" }}>Pinned Message</span>
                    <Box p={3} bgcolor="#303343" borderRadius="10px" color="#BEC0C9">
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                            Welcome Message to Group Members
                        </Typography>
                        <Typography variant="body2" mt={1}>
                            Hello and welcome!
                            <br /><br />
                            Feel free to post your welcome messages, guidelines, or any helpful tips for new members here.
                            Let’s keep our community vibrant and supportive by following our community guidelines and respecting each other.
                            <br /><br />
                            To customize this message, just click on the edit button and replace this text with yours.
                            <br /><br />
                            Step up and craft something truly amazing with your group!
                        </Typography>
                    </Box>
                </Box>
                <Box m={2} sx={{ backgroundColor: "#303343", borderRadius: "10px" }}>
                    <List>
                        <ListItem >
                            <ListItemIcon sx={{ ml: 0.75 }}>
                                <AddIcon width="28px" height="28px" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Add Members"
                                primaryTypographyProps={{
                                    style: { color: "#BEC0C9", fontSize: "14px" },
                                }}
                            />
                        </ListItem>
                        {members.map((contact, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={contact.full_name}
                                        src={contact.profile_image_url?.sharable}
                                        style={{ width: "36px", height: "36px" }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${contact.full_name} ${contact.admin ? (` (Admin)`) : ""}`}
                                    primaryTypographyProps={{
                                        style: { color: "#BEC0C9", fontSize: "15px" },
                                    }}
                                />
                                <Box display="flex" alignItems="center" gap={1}>
                                    {contact.admin ? (<span style={{ fontSize: "14px", color: "#BEC0C9" }}>Admin</span>) : ""}
                                    <IconButton component="span" sx={{ color: "#fff" }}>
                                        <DotsIcon width="14px" height="14px" />
                                    </IconButton>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <Dialog
                open={closeChat}
                sx={{
                    maxWidth: "400px",
                    margin: "auto",
                    "& .MuiPaper-root": {
                        backgroundColor: "#BEC0C9",
                        color: "#212330",
                        padding: "15px",
                        borderRadius: "15px"
                    },
                    "& .MuiDialogTitle-root": {
                        padding: "5px",
                        color: "#212330",
                        textAlign: "center",
                    },
                    "& .MuiDialogContentText-root": {
                        textAlign: "center",
                        color: "#212330",
                    },
                    "& .MuiDialogContent-root": {
                        padding: "5px",
                    },
                    "& .MuiDialogActions-root": {
                        padding: "5px",
                    },
                    "& .MuiDialog-container": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <DialogTitle fontWeight="bold">Close Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText fontSize="13px" fontFamily="Helvetica">
                        Are you sure you want to close this chat? This action will permanently delete all messages and cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                    }}
                >
                    <Button
                        onClick={handleDelete}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#ff2557",
                            "&:hover": { backgroundColor: "#ff4b6f", color: "#fff" },
                        }}
                    >
                        <b>Confirm</b>
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#212330",
                            "&:hover": { backgroundColor: "#A6A6A6", color: "#212330" },
                        }}
                    >
                        <b>Cancel</b>
                    </Button>
                </DialogActions>

            </Dialog>
            <Dialog
                open={leaveChat}
                sx={{
                    maxWidth: "400px",
                    margin: "auto",
                    "& .MuiPaper-root": {
                        backgroundColor: "#BEC0C9",
                        color: "#212330",
                        padding: "15px",
                        borderRadius: "15px"
                    },
                    "& .MuiDialogTitle-root": {
                        padding: "5px",
                        color: "#212330",
                        textAlign: "center",
                    },
                    "& .MuiDialogContentText-root": {
                        textAlign: "center",
                        color: "#212330",
                    },
                    "& .MuiDialogContent-root": {
                        padding: "5px",
                    },
                    "& .MuiDialogActions-root": {
                        padding: "5px",
                    },
                    "& .MuiDialog-container": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <DialogTitle fontWeight="bold">Leave Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText fontSize="13px" fontFamily="Helvetica">
                        Are you sure you want to Leave this chat? This action will permanently delete all messages and cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                    }}
                >
                    <Button
                        onClick={handleLeave}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#ff2557",
                            "&:hover": { backgroundColor: "#ff4b6f", color: "#fff" },
                        }}
                    >
                        <b>Confirm</b>
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#212330",
                            "&:hover": { backgroundColor: "#A6A6A6", color: "#212330" },
                        }}
                    >
                        <b>Cancel</b>
                    </Button>
                </DialogActions>

            </Dialog>
            <Dialog
                open={reportChat}
                sx={{
                    maxWidth: "400px",
                    margin: "auto",
                    "& .MuiPaper-root": {
                        backgroundColor: "#BEC0C9",
                        color: "#212330",
                        padding: "15px",
                        borderRadius: "15px"
                    },
                    "& .MuiDialogTitle-root": {
                        padding: "5px",
                        color: "#212330",
                        textAlign: "center",
                    },
                    "& .MuiDialogContentText-root": {
                        textAlign: "center",
                        color: "#212330",
                    },
                    "& .MuiDialogContent-root": {
                        padding: "5px",
                    },
                    "& .MuiDialogActions-root": {
                        padding: "5px",
                    },
                    "& .MuiDialog-container": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <DialogTitle fontWeight="bold">Report Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText fontSize="13px" fontFamily="Helvetica">
                        Report this chat for inappropriate content? Please confirm to proceed. Note: Misuse of this feature may result in consequences for your account.
                        <br /><br />
                        Report Illegal EU Content <span style={{ color: "#2196F3", }}>here.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                    }}
                >
                    <Button
                        onClick={handleReport}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#ff2557",
                            "&:hover": { backgroundColor: "#ff4b6f", color: "#fff" },
                        }}
                    >
                        <b>Confirm</b>
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#212330",
                            "&:hover": { backgroundColor: "#A6A6A6", color: "#212330" },
                        }}
                    >
                        <b>Cancel</b>
                    </Button>
                </DialogActions>

            </Dialog>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "#1a1b26",
                        width: 500,
                        height: 500,
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: 1
                        }}
                    >
                        <IconButton component="span" sx={{ color: "#fff", margin: "5px" }} onClick={() => setOpen(false)}>
                            <ArrowIcon width="16px" height="16px" />
                        </IconButton>
                        <Button sx={{
                            borderRadius: "100px",
                            backgroundColor: "#FFF",
                            color: "#000",
                            margin: "5px",
                            fontSize: "12px",
                            "&:hover": {
                                backgroundColor: "#FFF",
                            },
                        }}
                            onClick={handleApply}
                        >
                            <b>Apply</b>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            backgroundColor: "#545768",
                            position: "relative",
                        }}
                    >
                        {selectedImage && (
                            <Cropper
                                image={selectedImage}
                                crop={crop}
                                zoom={zoom}
                                aspect={editType === "profile" ? 1 : 5}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                cropShape="rect"
                                showGrid={false}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: 2,
                        }}
                    >
                        <IconButton
                            onClick={() => setZoom((prev) => Math.max(1, prev - 0.1))}
                            disabled={zoom <= 1}
                            sx={{
                                color: zoom > 1 ? "#fff" : "#555",
                            }}
                        >
                            <Typography>🔍</Typography>
                        </IconButton>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={handleZoomChange}
                            sx={{
                                mx: 2,
                                color: "#FF2557",
                            }}
                        />
                        <IconButton
                            onClick={() => setZoom((prev) => Math.min(3, prev + 0.1))}
                            disabled={zoom >= 3}
                            sx={{
                                color: zoom < 3 ? "#fff" : "#555",
                            }}
                        >
                            <Typography>🔎</Typography>
                        </IconButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default GroupSetting;
