import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    InputBase,
    InputAdornment,
    Paper,
    Typography,
    Box,
    IconButton,
    CircularProgress,
    Menu,
    MenuItem,
    ListItemIcon
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../Assets/search.svg";
import { ReactComponent as AddIcon } from "../../Assets/Plus.svg";
import { ReactComponent as FireIcon } from "../../Assets/Fire.svg";
import { ReactComponent as LogoIcon } from "../../Assets/Ellipse.svg";
import { ReactComponent as DotsIcon } from "../../Assets/dots.svg";
import { ReactComponent as BlockIcon } from "../../Assets/user-block.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/delete-02.svg";

const AllChatsList = () => {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const open = Boolean(anchorEl);
    const { onSelectChat } = useOutletContext();

    const fetchChats = () => {
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        axios
            .get("https://api.locaided.com/v1/users/get_all_chats?type=direct", {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            })
            .then((response) => {
                setChats(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching chats:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchChats();
    }, []);

    const sortedChats = [...chats].sort((a, b) =>
        a.message.opposite_user_full_name.localeCompare(b.message.opposite_user_full_name)
    );

    const groupedChats = sortedChats.reduce((acc, chat) => {
        const firstLetter = chat.message.opposite_user_full_name[0].toUpperCase();
        if (!acc[firstLetter]) acc[firstLetter] = [];
        acc[firstLetter].push(chat);
        return acc;
    }, {});

    const handleClick = async (event, id) => {
        setAnchorEl(event.currentTarget);
        setDeleteId(id);
    };

    const handleDelete = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/users/${deleteId}/delete_chat`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            handleClose();
            console.log(response, "success");
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const handleClose = () => {
        fetchChats();
        setAnchorEl(null);
        setDeleteId(null);
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FD445D" }} />
                <Typography variant="h6" style={{ marginTop: "18px" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <>
            <Box sx={{
                pt: 1,
                backgroundColor: "#212330",
                px: "1rem",
                height: '87vh',
                borderRadius: "10px",
            }}>
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                    <Paper
                        style={{
                            padding: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            margin: "10px auto",
                            borderRadius: "25px",
                        }}
                    >
                        <InputBase
                            style={{ marginLeft: 8, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ "aria-label": "search" }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </Paper>
                    <IconButton component="span" sx={{
                        backgroundColor: "#ff2557",
                        "&:hover": {
                            backgroundColor: "#ff2557",
                        },
                    }}
                        onClick={() => navigate("contact")}
                    >
                        <AddIcon width="16px" height="16px" />
                    </IconButton>
                </Box>
                <Box>
                    {chats.length === 0 ? (
                        <Box sx={{
                            mt: 10,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            gap: 1
                        }}>
                            <LogoIcon width="32px" height="34px" style={{ marginBottom: "2px" }} />
                            <Box alignItems="flex-start" maxWidth="40%">
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Explore New Chat Features!
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Up to 500 members (Group Chats)
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Mute words and tailor your chat experience to avoid unwanted content.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Inactive users removed after 4 weeks of silence.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    All messages are automatically deleted after one week.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Group auto-deleted if report count exceeds our set threshold.
                                </Typography>
                                <Box sx={{ mb: 1, border: "1px solid #E6E6E6", borderRadius: "0 16px 16px 16px", paddingRight: "5px", display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="body2"
                                        p={1}
                                        sx={{
                                            fontSize: "13px",
                                        }}
                                    >
                                        Enough reading… let’s get chatting! <b>Send your first message to open the chat</b> and make it memorable!
                                    </Typography>
                                    <FireIcon width="26%" height="26%" />
                                </Box>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    fontWeight="bold"
                                    sx={{
                                        bgcolor: "#fff",
                                        color: "#000",
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Create New Chat
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <List>
                            {Object.keys(groupedChats).sort().map((letter) => (
                                <div key={letter}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            padding: "10px 0",
                                            fontSize: "15px",
                                            fontFamily: "Roboto",
                                        }}
                                    >
                                        {letter}
                                    </Typography>
                                    <Box sx={{ backgroundColor: "#303343", borderRadius: "10px" }}>
                                        {groupedChats[letter].map((chat, index) => (
                                            <div key={index}>
                                                <ListItem button onClick={() => onSelectChat(chat.message)}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={chat.message.opposite_user_full_name}
                                                            src={chat.message.opposite_user_profile_image.sharable}
                                                            style={{ width: "36px", height: "36px" }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={chat.message.opposite_user_full_name}
                                                        style={{ fontSize: "16px" }}
                                                    />
                                                    <IconButton component="span" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClick(e, chat.message.opposite_user_id);
                                                    }} sx={{ color: "#fff" }}>
                                                        <DotsIcon width="16px" height="16px" />
                                                    </IconButton>
                                                </ListItem>
                                            </div>
                                        ))}
                                    </Box>
                                </div>
                            ))}
                        </List>

                    )}
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    sx: {
                        borderRadius: "8px",
                        backgroundColor: "#BEC0C9",
                        color: "#000",
                    },
                }}
            >
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon width="18px" height="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Delete Chat" primaryTypographyProps={{
                        style: { fontSize: "14px" },
                    }} />
                </MenuItem>

                <MenuItem onClick={() => console.log("block click")}>
                    <ListItemIcon>
                        <BlockIcon width="18px" height="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Mute User" primaryTypographyProps={{
                        style: { fontSize: "14px", color: "#FF2557" },
                    }} />
                </MenuItem>
            </Menu>
        </>
    );
};

export default AllChatsList;
