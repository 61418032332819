import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    InputBase,
    InputAdornment,
    Paper,
    Typography,
    Box,
    IconButton,
    CircularProgress,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../Assets/search.svg";
import { ReactComponent as AddIcon } from "../../Assets/Plus.svg";


const ActiveChats = () => {
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const { onSelectChat } = useOutletContext();

    const fetchContacts = () => {
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        axios
            .get("https://api.locaided.com/v1/users/get_radius", {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            })
            .then((response) => {
                setContacts(
                    (response.data.data.emergency_contacts || []).filter(
                        (contact) => contact !== null && contact.muted !== true
                    )
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching radius:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchContacts();
    }, []);

    const sortedContacts = [...contacts].sort((a, b) =>
        a.full_name.localeCompare(b.full_name)
    );

    const groupedContacts = sortedContacts.reduce((acc, contact) => {
        const firstLetter = contact.full_name[0].toUpperCase();
        if (!acc[firstLetter]) acc[firstLetter] = [];
        acc[firstLetter].push(contact);
        return acc;
    }, {});

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FD445D" }} />
                <Typography variant="h6" style={{ marginTop: "18px" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={{
            pt: 1,
            backgroundColor: "#212330",
            px: "1rem",
            height: '87vh',
            borderRadius: "10px",
        }}>
            <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                <Paper
                    style={{
                        padding: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        margin: "10px auto",
                        borderRadius: "25px",
                    }}
                >
                    <InputBase
                        style={{ marginLeft: 8, flex: 1 }}
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </Paper>
                <IconButton component="span" sx={{
                    backgroundColor: "#ff2557",
                    "&:hover": {
                        backgroundColor: "#ff2557",
                    },
                }}>
                    <AddIcon width="16px" height="16px" />
                </IconButton>
            </Box>
            <Typography variant="subtitle1">Contacts</Typography>
            <List>{contacts.length === 0 ? (
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: "center",
                        color: "#A6A6A6",
                        marginTop: 2,
                        fontFamily: "Roboto",
                    }}
                >
                    No Contacts exist
                </Typography>
            ) : (
                Object.keys(groupedContacts).sort().map((letter) => (
                    <div key={letter}>
                        <Typography variant="h6" style={{ padding: "10px 0", fontSize: "15px", fontFamily: "Roboto" }}>
                            {letter}
                        </Typography>
                        <Box sx={{ backgroundColor: "#303343", borderRadius: "10px" }}>
                            {groupedContacts[letter].map((contact, index) => (
                                <div key={index}>
                                    <ListItem button onClick={() => onSelectChat(contact)}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={contact.full_name}
                                                src={contact.profile_image_url.sharable}
                                                style={{ width: "36px", height: "36px" }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={contact.full_name}
                                            style={{ fontSize: "16px" }}
                                        />
                                    </ListItem>
                                </div>
                            ))}
                        </Box>
                    </div>
                ))
            )}
            </List>
        </Box>
    );
};

export default ActiveChats;
