import React from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import Home from "./Components/Home/Home";
import Login from "./Components/Auth/Login";
import ForgotPass from "./Components/Auth/ForgotPass";
import Signup from "./Components/Auth/Signup";
import Countdown from "./Components/Home/Countdown";
import Dashboard from "./Components/Dashboard";
import UserProfile from "./Components/Profile/UserProfile";
import Feed from "./Components/Layout/Feed";
import Evidences from "./Components/Profile/Evidences";
import UserSettings from "./Components/Profile/UserSettings";
import Posts from "./Components/Profile/Posts";
import Chats from "./Components/Messages/Chats";
import Settings from "./Components/Layout/Settings";
import Contacts from "./Components/Contacts/Contacts";
import AddContact from "./Components/Contacts/AddContact";
import MutedContacts from "./Components/Contacts/MutedContacts";
import AdditionalSettings from "./Components/Settings/AdditionalSettings";
import AboutApp from "./Components/Settings/AboutApp";
import DeactivateAccount from "./Components/Settings/DeactivateAccount";
import LiveEvidences from "./Components/Layout/LiveEvidences";
import PageNotFound from "./Components/error/PageNotFound";
import ActiveChats from "./Components/Messages/ActiveChats";
import ChatWindow from "./Components/Messages/ChatWindow";
import ChatSetting from "./Components/Messages/ChatSetting";
import AllChatsList from "./Components/Messages/AllChatsList";
import AllGroups from "./Components/Messages/Group/AllGroups";
import GroupChatWindow from "./Components/Messages/Group/GroupChatWindow";
import GroupSetting from "./Components/Messages/Group/GroupSetting";
import CreateGroup from "./Components/Messages/Group/CreateGroup";
import AllLocation from "./Components/Messages/Location/AllLocation";
import FlashPoint from "./Components/Flash Point/FlashPoint"
import AllNotification from "./Components/Flash Point/AllNotification";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/countdown" element={<Countdown days={120} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<ForgotPass />} />

      <Route path="/dashboard" element={<Protected Component={Dashboard} />}>
        <Route index element={<Feed />} />
        <Route path="live_evidences" element={<LiveEvidences />} />
        <Route path="add_contact" element={<AddContact />} />
        <Route path="muted_contacts" element={<MutedContacts />} />
        <Route path="flash_point" element={<FlashPoint />}>
          <Route index element={<AllNotification />} />
        </Route>
        <Route path="chats" element={<Chats />} >
          <Route index element={<AllChatsList />} />
          <Route path="contact" element={<ActiveChats />} />
          <Route path="private/:id" element={<ChatWindow />} />
          <Route path="setting" element={<ChatSetting />} />
          <Route path="group" element={<AllGroups />} />
          <Route path="group_chat/:id" element={<GroupChatWindow />} />
          <Route path="group_setting/:id" element={<GroupSetting />} />
          <Route path="create_group" element={<CreateGroup />} />
          <Route path="location" element={<AllLocation />} />

        </Route>
        <Route path="contacts" element={<Contacts />} />
        <Route path="settings" element={<Settings />} />
        <Route path="additional_settings" element={<AdditionalSettings />} />
        <Route path="about_app" element={<AboutApp />} />
        <Route path="deactivate_account" element={<DeactivateAccount />} />
        <Route path="profile" element={<UserProfile />}>
          <Route index element={<Posts />} />
          <Route path="posts" element={<Posts />} />
          <Route path="eys" element={<Evidences />} />
          <Route path="settings" element={<UserSettings />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
