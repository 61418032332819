import React from "react";
import {
  Toolbar,
  Button,
  Box,
  Avatar,
  Typography,
  Stack,
} from "@mui/material";
import { ReactComponent as WalletSVG } from "../../Assets/wallet.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Pic2 from "../../Assets/profile/2.jpg";
import Pic3 from "../../Assets/profile/3.jpg";
import Pic4 from "../../Assets/profile/4.jpg";

const TopBar = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isLiveLocation = location.pathname === "/dashboard";
  const isEYS = location.pathname === "/dashboard/live_evidences";

  return (
    <Box
      style={{ backgroundColor: "#212330", borderRadius: "12px" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="text"
            size="small"
            style={{
              backgroundColor: isLiveLocation ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard")}
          >
            Live Location
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              backgroundColor: isEYS ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/live_evidences")}
          >
            EYS
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar alt="User Avatar" src={user.profile_image_url.sharable} />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography color="#F0F1F5">{user.full_name}</Typography>
            <Typography color="#B3B6C4" style={{ fontSize: "0.75rem" }}>
              {user.username}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </Box>
  );
};

const CircularIcons = () => {
  const icons = [
    { label: "News", src: Pic2, unread: false },
    { label: "Moments", src: Pic3, unread: false },
    { label: "Activity", src: Pic4, unread: true },
    { label: "Hot Spot", src: Pic2, unread: false },
    { label: "Alert", src: Pic3, unread: true },
    { label: "SOS", src: Pic4 },
  ];

  return (
    <Stack direction="row" spacing={4} style={{ padding: "1rem" }}>
      {icons.map((icon, index) => (
        <Stack key={index} direction="column" alignItems="center" spacing={1}>
          <Avatar
            src={icon.src}
            alt={icon.label}
            style={{
              border: icon.unread ? "3px solid #FF2557" : "3px solid transparent",
              alignItems: "center",
              width: "60px",
              height: "60px",
            }}
          />
          <Typography variant="caption">{icon.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

const BalanceSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#212330",
        padding: "1rem",
        borderRadius: "10px",
        width: "350px",
        color: "#fff",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <WalletSVG width="20px" height="18px" />
          <Typography variant="body2" color="#D7D9E3">EYS Balance</Typography>
          <Box
            sx={{
              backgroundColor: "#0FCD39",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></Box>
        </Box>
        <Typography variant="contain" color="#F0F1F5" fontFamily="urbanist">
          584 Coins
        </Typography>
      </Box>

      <Button variant="body2" style={{ color: "#F0F1F5", fontSize: "0.75rem" }}>
        Buy coins
      </Button>
    </Box>
  );
};

const NavBar = ({ user }) => {
  return (
    <Box sx={{ width: "85%", pt: "1rem", pl: "1rem" }}>
      <TopBar user={user} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CircularIcons />
        <BalanceSection />
      </Stack>
    </Box>
  );
};

export default NavBar;
