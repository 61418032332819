import React from "react";
import {
  Toolbar,
  Button,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const TopBar = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAll = location.pathname === "/dashboard/flash_point";
  const isLikes = location.pathname === "/dashboard/flash_point/likes";
  const isBoosts = location.pathname === "/dashboard/flash_point/boosts";
  const isReplies = location.pathname === "/dashboard/flash_point/replies";
  const isTransactions = location.pathname === "/dashboard/flash_point/transactions";

  return (
    <Box
      style={{ backgroundColor: "#212330", borderRadius: "12px" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isAll ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/flash_point")}
          >
            All
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isLikes ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/flash_point/likes")}
          >
            Likes
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isBoosts ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/flash_point/boosts")}
          >
            Boosts
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isReplies ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/flash_point/replies")}
          >
            Replies
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isTransactions ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/flash_point/transactions")}
          >
            transactions
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar alt="User Avatar" src={user.profile_image_url.sharable} />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography color="#F0F1F5">{user.full_name}</Typography>
            <Typography color="#B3B6C4" style={{ fontSize: "0.65rem" }}>
              {user.username}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </Box>
  );
};

const NavBar = ({ user }) => {
  return (
    <Box sx={{ width: "85%", pt: "1rem", pl: "1rem" }}>
      <TopBar user={user} />
    </Box>
  );
};

export default NavBar;
