import React from 'react'
import {
  Button,
  Typography,
  Box,
  Grid
} from "@mui/material";
import { Outlet, useOutletContext } from "react-router-dom";
import LiveFeedFilter from "../Layout/LiveFeedFilter"
import NavBar from "./NavBar";
import { ReactComponent as WalletSVG } from "../../Assets/wallet.svg";

const BalanceSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#212330",
        padding: "1rem",
        mb:2,
        ml:1,
        borderRadius: "10px",
        width: 350,
        color: "#fff",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <WalletSVG width="20px" height="18px" />
          <Typography variant="body2" color="#D7D9E3">EYS Balance</Typography>
          <Box
            sx={{
              backgroundColor: "#0FCD39",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></Box>
        </Box>
        <Typography variant="contain" color="#F0F1F5" fontFamily="urbanist">
          584 Coins
        </Typography>
      </Box>

      <Button variant="body2" style={{ color: "#F0F1F5", fontSize: "0.75rem" }}>
        Buy coins
      </Button>
    </Box>
  );
};

const FlashPoint = () => {
  const { user } = useOutletContext();

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <NavBar user={user} />
      <Grid item xs={12} md={7}>
        <Outlet/>
      </Grid>
      <Grid item xs={12} md={3} pl={0}>
        <BalanceSection/>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  )
}

export default FlashPoint