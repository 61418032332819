/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    Typography,
    IconButton,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    Switch,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import VerifiedIconPng from "../../Assets/verified.png";
import pushPng from "../../Assets/Push notification.png";
import { ReactComponent as ArrowIcon } from "../../Assets/Arrow.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/delete-03.svg";
import { ReactComponent as CloseIcon } from "../../Assets/log-out.svg";
import { ReactComponent as ReportIcon } from "../../Assets/alert-circle.svg";
import { ReactComponent as BlockIcon } from "../../Assets/user-block.svg";

const ChatSetting = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deleteId, setDeleteId] = useState(null);
    const [closeChat, setCloseChat] = useState(false);
    const [reportChat, setReportChat] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);

    useEffect(() => {
        const savedChat = localStorage.getItem("selectedChat");
        if (savedChat) {
            setSelectedChat(JSON.parse(savedChat));
        }
    }, []);

    console.log(id)

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setCloseChat(true);
    };

    const handleClear = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/users/${deleteId}/clear_chat`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            navigate(-1)
            handleClose();
            console.log(response, "success");
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const handleClickReport = () => {
        setReportChat(true);
    };

    const handleClose = () => {
        setCloseChat(false);
        setDeleteId(null)
        setReportChat(false);
    };

    return (
        <>
            <Box sx={{ height: "88vh", display: "flex", flexDirection: "column", backgroundColor: "#212330", borderRadius: "12px" }}>
                <Box display="flex" alignItems="center" p={2} gap={2}>
                    <IconButton component="span" sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
                        <ArrowIcon width="16px" height="16px" />
                    </IconButton>
                    <Typography variant="body1" fontWeight="bold"> Settings</Typography>
                </Box>
                <Box display="flex" alignItems="center" px={2} py={1} mx={2} gap={2} sx={{ backgroundColor: "#303343", borderRadius: "10px" }}>
                    <Avatar
                        alt={selectedChat?.opposite_user_full_name}
                        src={selectedChat?.opposite_user_profile_image?.sharable}
                        style={{ width: "50px", height: "50px", }}
                    />
                    <Box sx={{ textAlign: "flex-start" }}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {selectedChat?.opposite_user_full_name}
                            {selectedChat?.verified && (
                                <img
                                    src={VerifiedIconPng}
                                    alt="Verified"
                                    style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                                />
                            )}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            @{selectedChat?.opposite_user_full_name}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" px={2} py={1} m={2} gap={2} sx={{ backgroundColor: "#303343", borderRadius: "10px" }}>
                    <List
                        PaperProps={{
                            sx: {
                                borderRadius: "10px",
                                backgroundColor: "#303343",
                                color: "#D7D9E3",
                            },
                        }}
                    >
                        <ListItem button onClick={() => console.log(`Copy Link for message ID:`)}>
                            <ListItemIcon>
                                <DeleteIcon width="18px" height="18px" />
                            </ListItemIcon>
                            <ListItemText primary="Empty Chat" primaryTypographyProps={{
                                style: { fontSize: "14px" },
                            }} />
                        </ListItem>
                        <ListItem button onClick={() => handleClickOpen(selectedChat?.user_id)}>
                            <ListItemIcon>
                                <CloseIcon width="18px" height="18px" />
                            </ListItemIcon>
                            <ListItemText primary="Close Chat" primaryTypographyProps={{
                                style: { fontSize: "14px" },
                            }} />
                        </ListItem>
                        <ListItem button onClick={handleClickReport}>
                            <ListItemIcon>
                                <ReportIcon width="18px" height="18px" />
                            </ListItemIcon>
                            <ListItemText primary={`Report ${selectedChat?.opposite_user_full_name}`} primaryTypographyProps={{
                                style: { fontSize: "14px", color: "#FF2557" },
                            }} />
                        </ListItem>
                        <ListItem button onClick={() => console.log(`Copy Link for message ID:`)}>
                            <ListItemIcon>
                                <BlockIcon width="18px" height="18px" />
                            </ListItemIcon>
                            <ListItemText primary={`Block ${selectedChat?.opposite_user_full_name}`} primaryTypographyProps={{
                                style: { fontSize: "14px", color: "#FF2557" },
                            }} />
                        </ListItem>
                    </List>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" px={2} py={1} m={1} ml={5} color="#BEC0C9">
                    <Typography variant="h5" fontWeight={700} fontFamily="Helvetica">
                        Notifications
                    </Typography>
                    <span style={{ fontSize: "14px", fontFamily: "Helvetica" }}>Disable both options to completely mute notifications.</span>
                </Box>
                <Box px={2} gap={1} borderRadius="10px">
                    <span style={{ fontSize: "14px", fontFamily: "Helvetica", color: "#616161", marginLeft: "1rem" }}>In Browser</span>
                    <Box px={3} py={1} bgcolor="#303343" borderRadius="10px" color="#BEC0C9">
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#A5A6B0", color: "#000", borderRadius: "10px", padding: "10px", }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, }}>
                                <Avatar
                                    alt={selectedChat?.opposite_user_full_name}
                                    src={selectedChat?.opposite_user_profile_image.sharable}
                                    sx={{ width: "40px", height: "40px" }}
                                />
                                <Box>
                                    <Typography variant="body2" fontWeight="bold">
                                        {selectedChat?.opposite_user_full_name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        Hi how are you?
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", }}>
                                <Typography variant="caption" sx={{ fontSize: "14px", fontFamily: "Helvetica", color: "#616161", }}>
                                    1h ago
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Typography variant="caption" color="textSecondary">
                                Preview Message
                            </Typography>
                            <Switch
                                defaultChecked
                                size="small"
                                disableRipple
                                sx={{
                                    width: 42,
                                    height: 24,
                                    padding: 0,
                                    display: "flex",
                                    "& .MuiSwitch-switchBase": {
                                        "&.Mui-checked": {
                                            transform: "translateX(18px)",
                                            color: "#fff",
                                            "& + .MuiSwitch-track": {
                                                backgroundColor: "#FF2557",
                                                opacity: 1,
                                                border: 0,
                                            },
                                        },
                                    },
                                    "& .MuiSwitch-thumb": {
                                        width: 18,
                                        height: 18,
                                        borderRadius: "50%",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                        transition: "all 200ms",
                                        backgroundColor: "#fff",
                                    },
                                    "& .MuiSwitch-track": {
                                        borderRadius: 13,
                                        height: 24,
                                        opacity: 1,
                                        backgroundColor: "#E9E9EA",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box px={2} mt={2} gap={1} borderRadius="10px">
                    <span style={{ fontSize: "14px", fontFamily: "Helvetica", color: "#616161", marginLeft: "1rem" }}>Push Notification</span>
                    <Box px={3} py={1} bgcolor="#303343" borderRadius="10px" color="#BEC0C9">
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#A5A6B0", color: "#000", borderRadius: "10px", padding: "10px", }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, }}>
                                <Avatar
                                    alt={selectedChat?.opposite_user_full_name}
                                    src={pushPng}
                                    sx={{ width: "40px", height: "40px" }}
                                />
                                <Box>
                                    <Typography variant="body2" fontWeight="bold">
                                        {selectedChat?.opposite_user_full_name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        New Message
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", }}>
                                <Typography variant="caption" sx={{ fontSize: "14px", fontFamily: "Helvetica", color: "#616161", }}>
                                    1h ago
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Typography variant="caption" color="textSecondary">
                                Preview Message
                            </Typography>
                            <Switch
                                size="small"
                                disableRipple
                                sx={{
                                    width: 42,
                                    height: 24,
                                    padding: 0,
                                    display: "flex",
                                    "& .MuiSwitch-switchBase": {
                                        "&.Mui-checked": {
                                            transform: "translateX(18px)",
                                            color: "#fff",
                                            "& + .MuiSwitch-track": {
                                                backgroundColor: "#FF2557",
                                                opacity: 1,
                                                border: 0,
                                            },
                                        },
                                    },
                                    "& .MuiSwitch-thumb": {
                                        width: 18,
                                        height: 18,
                                        borderRadius: "50%",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                        transition: "all 200ms",
                                        backgroundColor: "#fff",
                                    },
                                    "& .MuiSwitch-track": {
                                        borderRadius: 13,
                                        height: 24,
                                        opacity: 1,
                                        backgroundColor: "#E9E9EA",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={closeChat}
                sx={{
                    maxWidth: "400px",
                    margin: "auto",
                    "& .MuiPaper-root": {
                        backgroundColor: "#BEC0C9",
                        color: "#212330",
                        padding: "15px",
                        borderRadius: "15px"
                    },
                    "& .MuiDialogTitle-root": {
                        padding: "5px",
                        color: "#212330",
                        textAlign: "center",
                    },
                    "& .MuiDialogContentText-root": {
                        textAlign: "center",
                        color: "#212330",
                    },
                    "& .MuiDialogContent-root": {
                        padding: "5px",
                    },
                    "& .MuiDialogActions-root": {
                        padding: "5px",
                    },
                    "& .MuiDialog-container": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <DialogTitle fontWeight="bold">Close Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText fontSize="13px" fontFamily="Helvetica">
                        Are you sure you want to close this chat? This action will permanently delete all messages and cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                    }}
                >
                    <Button
                        onClick={handleClear}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#ff2557",
                            "&:hover": { backgroundColor: "#ff4b6f", color: "#fff" },
                        }}
                    >
                        <b>Confirm</b>
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#212330",
                            "&:hover": { backgroundColor: "#A6A6A6", color: "#212330" },
                        }}
                    >
                        <b>Cancel</b>
                    </Button>
                </DialogActions>

            </Dialog>
            <Dialog
                open={reportChat}
                sx={{
                    maxWidth: "400px",
                    margin: "auto",
                    "& .MuiPaper-root": {
                        backgroundColor: "#BEC0C9",
                        color: "#212330",
                        padding: "15px",
                        borderRadius: "15px"
                    },
                    "& .MuiDialogTitle-root": {
                        padding: "5px",
                        color: "#212330",
                        textAlign: "center",
                    },
                    "& .MuiDialogContentText-root": {
                        textAlign: "center",
                        color: "#212330",
                    },
                    "& .MuiDialogContent-root": {
                        padding: "5px",
                    },
                    "& .MuiDialogActions-root": {
                        padding: "5px",
                    },
                    "& .MuiDialog-container": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <DialogTitle fontWeight="bold">Report Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText fontSize="13px" fontFamily="Helvetica">
                        Report this chat for inappropriate content? Please confirm to proceed. Note: Misuse of this feature may result in consequences for your account.
                        <br /><br />
                        Report Illegal EU Content <span style={{ color: "#2196F3", }}>here.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                    }}
                >
                    <Button
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#ff2557",
                            "&:hover": { backgroundColor: "#ff4b6f", color: "#fff" },
                        }}
                    >
                        <b>Confirm</b>
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{
                            flex: 1,
                            backgroundColor: "transparent",
                            color: "#212330",
                            "&:hover": { backgroundColor: "#A6A6A6", color: "#212330" },
                        }}
                    >
                        <b>Cancel</b>
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default ChatSetting;
