import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    InputBase,
    InputAdornment,
    Paper,
    Typography,
    Box,
    Button,
    IconButton,
    FormControlLabel,
    Radio,
    Slider,
    Modal,
    CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cropper from "react-easy-crop";
import { ReactComponent as SearchIcon } from "../../../Assets/search.svg";
import { ReactComponent as UploadIcon } from "../../../Assets/image-upload.svg";
import { ReactComponent as ArrowIcon } from "../../../Assets/Arrow.svg";

const CreateGroup = () => {
    const navigate = useNavigate();
    const [contacts, setContacts] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reqLoading, setReqLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [editType, setEditType] = useState("");

    const handleImageChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setEditType(type);
            setOpen(true);
        }
    };

    const handleZoomChange = (event, newValue) => {
        setZoom(newValue);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const getCroppedImage = async (imageSrc, croppedAreaPixels) => {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.src = imageSrc;
        const { width, height } = croppedAreaPixels;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        img.onload = () => {
            ctx.drawImage(
                img,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                croppedAreaPixels.width,
                croppedAreaPixels.height
            );
        };

        return new Promise((resolve) => {
            img.onload = () => {
                ctx.drawImage(
                    img,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );
                resolve(canvas.toDataURL());
            };
        });
    };

    const handleApply = async () => {
        const croppedImage = await getCroppedImage(selectedImage, croppedAreaPixels);
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        if (editType === "profile") {
            setProfileImage(blob);
        } else if (editType === "banner") {
            setBannerImage(blob);
        }
        setOpen(false);
    };

    const fetchContacts = () => {
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        axios
            .get("https://api.locaided.com/v1/users/get_radius", {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            })
            .then((response) => {
                setContacts(
                    (response.data.data.emergency_contacts || []).filter(
                        (contact) => contact !== null && contact.muted !== true
                    )
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching radius:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchContacts();
    }, []);

    const sortedContacts = [...contacts].sort((a, b) =>
        a.full_name.localeCompare(b.full_name)
    );

    const groupedContacts = sortedContacts.reduce((acc, contact) => {
        const firstLetter = contact.full_name[0].toUpperCase();
        if (!acc[firstLetter]) acc[firstLetter] = [];
        acc[firstLetter].push(contact);
        return acc;
    }, {});

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    const handleToggle = (contact) => {
        setSelectedContacts((prevSelected) => {
            if (prevSelected.includes(contact.id)) {
                return prevSelected.filter((id) => id !== contact.id);
            } else {
                return [...prevSelected, contact.id];
            }
        });
    };

    const handleSubmit = async () => {
        setReqLoading(true)
        const url = `https://api.locaided.com/v1/groups`;
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        const formData = new FormData();
        formData.append("name", groupName);
        if (profileImage) {
            formData.append("group_image", profileImage, "profile.jpg");
        }
        if (bannerImage) {
            formData.append("group_banner", bannerImage, "banner.jpg");
        }
        if (selectedContacts && selectedContacts.length > 0) {
            formData.append("user_ids", JSON.stringify(selectedContacts));
        }

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response.data.message);
            navigate("/dashboard/chats/group");
            setGroupName("");
            setProfileImage(null);
            setBannerImage(null);
            setSelectedContacts([]);
            setReqLoading(false);
        } catch (error) {
            console.error(
                "Error sending group creation data:",
                error.response ? error.response.data : error.message
            );
        }
    };

    const totalSelected = selectedContacts.length;
    const isCreateGroupDisabled = groupName.trim() === "" || totalSelected < 2 || reqLoading;

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FD445D" }} />
                <Typography variant="h6" style={{ marginTop: "18px" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <>
            <Box sx={{
                pt: 1,
                backgroundColor: "#212330",
                px: "1rem",
                minHeight: '87vh',
                borderRadius: "10px",
            }}>
                <input
                    type="file"
                    accept="image/*"
                    id="profile-upload"
                    onChange={(e) => handleImageChange(e, "profile")}
                    style={{ display: "none" }}
                />
                <input
                    type="file"
                    accept="image/*"
                    id="banner-upload"
                    onChange={(e) => handleImageChange(e, "banner")}
                    style={{ display: "none" }}
                />
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                    <Paper
                        style={{
                            padding: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            margin: "10px auto",
                            borderRadius: "25px",
                        }}
                    >
                        <InputBase
                            style={{ marginLeft: 8, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ "aria-label": "search" }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </Paper>
                </Box>
                <Box mt={2}>
                    <Typography
                        variant="body2"
                        mx={2}
                        sx={{
                            color: "#BEC0C9",
                            fontWeight: 500,
                            fontSize: "15px",
                        }}
                    >
                        Group Name
                    </Typography>
                    <Paper
                        style={{
                            backgroundColor: "#303343",
                            padding: "2px",
                            display: "flex",
                            alignItems: "center",
                            width: "98%",
                            margin: "10px auto",
                            borderRadius: "10px",
                        }}
                    >
                        <InputBase
                            value={groupName}
                            onChange={handleGroupNameChange}
                            style={{ marginLeft: 8, flex: 1 }}
                            placeholder="Name . . ."
                        />
                    </Paper>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography
                            variant="body2"
                            mt={2}
                            mx={2}
                            sx={{
                                textAlign: "center",
                                color: "#BEC0C9",
                                fontWeight: 500,
                                fontSize: "15px",
                            }}
                        >
                            Group Picture
                        </Typography>
                        {profileImage ? (
                            <Avatar
                                src={URL.createObjectURL(profileImage)}
                                alt="Profile"
                                sx={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                    mt: 1,
                                }}
                                onClick={() => document.getElementById("profile-upload").click()}
                            />
                        ) : (
                            <IconButton
                                component="span"
                                onClick={() => document.getElementById("profile-upload").click()}
                                sx={{
                                    mt: 1,
                                    p: 4,
                                    backgroundColor: "#303343",
                                }}
                            >
                                <UploadIcon width="20px" height="20px" />
                            </IconButton>
                        )}
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography
                            variant="body2"
                            mt={3}
                            mx={2}
                            sx={{
                                color: "#BEC0C9",
                                fontWeight: 500,
                                fontSize: "15px",
                            }}
                        >
                            Group Chat Banner
                        </Typography>
                        {bannerImage ? (
                            <img
                                src={URL.createObjectURL(bannerImage)}
                                alt="Banner"
                                style={{
                                    width: "100%",
                                    height: "150px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    marginTop: "8px"
                                }}
                                onClick={() => document.getElementById("banner-upload").click()}
                            />
                        ) : (
                            <Button
                                onClick={() => document.getElementById("banner-upload").click()}
                                sx={{
                                    mt: 1,
                                    py: 6,
                                    px: 46,
                                    borderRadius: "10px",
                                    backgroundColor: "#303343",
                                }}
                            >
                                <UploadIcon width="26px" height="26px" />
                            </Button>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography
                            variant="body2"
                            mt={3}
                            mx={2}
                            sx={{
                                color: "#BEC0C9",
                                fontWeight: "bold",
                                fontSize: "12px",
                            }}
                        >
                            Add Members
                        </Typography>
                        <Typography
                            variant="body2"
                            mt={3}
                            mx={2}
                            sx={{
                                color: "#BEC0C9",
                                fontWeight: 500,
                                fontSize: "12px",
                            }}
                        >
                            {totalSelected} / 500
                        </Typography>
                        <Button sx={{
                            px: 2,
                            mt: 3,
                            borderRadius: "100px",
                            backgroundColor: "#FF2557",
                            fontSize: "12px",
                            color: "#FFF",
                            "&:hover": {
                                backgroundColor: "#FF2557",
                            },
                            "&.Mui-disabled": {
                                color: "#000",
                                backgroundColor: "#fff",
                            },
                        }}
                            disabled={isCreateGroupDisabled}
                            onClick={handleSubmit}
                        >
                            {reqLoading ? (
                                <CircularProgress size={24} sx={{ color: "#FF2557" }} />
                            ) : (
                                <b>Create Group</b>
                            )}

                        </Button>
                    </Box>
                    <List>
                        {contacts.length === 0 ? (
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    color: "#A6A6A6",
                                    marginTop: 2,
                                    fontFamily: "Roboto",
                                }}
                            >
                                No Contacts exist
                            </Typography>
                        ) : (
                            Object.keys(groupedContacts)
                                .sort()
                                .map((letter) => (
                                    <div key={letter}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                padding: "10px 0",
                                                fontSize: "15px",
                                                fontFamily: "Roboto",
                                            }}
                                        >
                                            {letter}
                                        </Typography>
                                        <Box
                                            sx={{
                                                backgroundColor: "#303343",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            {groupedContacts[letter].map((contact, index) => (
                                                <div key={index}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                alt={contact.full_name}
                                                                src={contact.profile_image_url.sharable}
                                                                style={{
                                                                    width: "36px",
                                                                    height: "36px",
                                                                }}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={contact.full_name}
                                                            style={{ fontSize: "16px" }}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={selectedContacts.includes(contact.id)}
                                                                    onClick={() => handleToggle(contact)}
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            borderRadius: '50%',
                                                                        },
                                                                        color: '#BDBDBD',
                                                                        '&.Mui-checked': {
                                                                            color: '#FF2557',
                                                                        },
                                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                                            color: '#FF2557',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    </ListItem>
                                                </div>
                                            ))}
                                        </Box>
                                    </div>
                                ))
                        )}
                    </List>
                </Box>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "#1a1b26",
                        width: 500,
                        height: 500,
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: 1
                        }}
                    >
                        <IconButton component="span" sx={{ color: "#fff", margin: "5px" }} onClick={() => setOpen(false)}>
                            <ArrowIcon width="16px" height="16px" />
                        </IconButton>
                        <Button sx={{
                            borderRadius: "100px",
                            backgroundColor: "#FFF",
                            color: "#000",
                            margin: "5px",
                            fontSize: "12px",
                            "&:hover": {
                                backgroundColor: "#FFF",
                            },
                        }}
                            onClick={handleApply}
                        >
                            <b>Apply</b>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            backgroundColor: "#545768",
                            position: "relative",
                        }}
                    >
                        {selectedImage && (
                            <Cropper
                                image={selectedImage}
                                crop={crop}
                                zoom={zoom}
                                aspect={editType === "profile" ? 1 : 5}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                cropShape="rect"
                                showGrid={false}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: 2,
                        }}
                    >
                        <IconButton
                            onClick={() => setZoom((prev) => Math.max(1, prev - 0.1))}
                            disabled={zoom <= 1}
                            sx={{
                                color: zoom > 1 ? "#fff" : "#555",
                            }}
                        >
                            <Typography>🔍</Typography>
                        </IconButton>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={handleZoomChange}
                            sx={{
                                mx: 2,
                                color: "#FF2557",
                            }}
                        />
                        <IconButton
                            onClick={() => setZoom((prev) => Math.min(3, prev + 0.1))}
                            disabled={zoom >= 3}
                            sx={{
                                color: zoom < 3 ? "#fff" : "#555",
                            }}
                        >
                            <Typography>🔎</Typography>
                        </IconButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default CreateGroup;
