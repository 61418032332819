import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate(-1);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", backgroundColor: "#212330", color: "#FEFEFE" }}
    >
      <Typography
        variant="h1"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        404
      </Typography>
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        Page Not Found
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "40px", textAlign: "center", maxWidth: "500px" }}
      >
        The page you are looking for does not exist. It might have been moved or
        deleted.
      </Typography>
      <Button
        variant="contained"
        onClick={handleGoHome}
        style={{ backgroundColor: "#FF2557", color: "#FEFEFE" }}
      >
        Go to Back
      </Button>
    </Grid>
  );
}

export default PageNotFound;
