import {
  Box,
  Typography,
  Grid,
  Container,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import SideMenu from "./Layout/SideMenu";

const Dashboard = () => {
  const [messages, setMessages] = useState(null);
  const [user, setUser] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");
  const [location, setLocation] = useState({
    lat: "",
    long: "",
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation((prevFormData) => ({
            ...prevFormData,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          }));
        },
        (error) => {
          setError("Error getting location. Please enable location services.");
          setOpenSnackbar(true);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setOpenSnackbar(true);
    }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchMessage = async () => {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      await axios
        .get("https://api.locaided.com/v1/messages/", {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
          params: {
            lat: location.lat,
            long: location.long,
          },
        })
        .then((response) => {
          setMessages(response.data.data.messages);
          setUser(response.data.data.user_details);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchMessage();
  }, [location]);

  if (!user && !messages) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Container
      maxWidth="100%"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          xl={3}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SideMenu />
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
          <Outlet context={{ user, messages }} />
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Dashboard;
