/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
    Avatar,
    TextField,
    Paper,
    Typography,
    IconButton,
    Box,
    Button,
    Stack,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    // Tooltip,
    CircularProgress,
} from "@mui/material";
import { useParams, useNavigate, } from "react-router-dom";
import { ReactComponent as GalleryIcon } from "../../../Assets/gallery.svg";
import { ReactComponent as VideoIcon } from "../../../Assets/video-square.svg";
import { ReactComponent as MicIcon } from "../../../Assets/Voice.svg";
import { ReactComponent as SendIcon } from "../../../Assets/Send.svg";
import { ReactComponent as ReactionIcon } from "../../../Assets/Reaction.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/delete.svg";
import { ReactComponent as SettingIcon } from "../../../Assets/setting-03.svg";
import { ReactComponent as ArrowIcon } from "../../../Assets/Arrow.svg";

const GroupChatWindow = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [image, setImage] = useState(null);
    const [audio, setAudio] = useState(null);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedChat, setSelectedChat] = useState(null);

    useEffect(() => {
        const savedChat = localStorage.getItem("selectedChat");
        if (savedChat) {
            setSelectedChat(JSON.parse(savedChat));
        }
    }, []);

    const handleImageChange = (e) => setImage(e.target.files[0]);
    const handleAudioChange = (e) => setAudio(e.target.files[0]);
    const handleVideoChange = (e) => setVideo(e.target.files[0]);

    const fetchMessages = (isInitialLoad = false) => {
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        if (isInitialLoad) {
            setLoading(true);
        }
        axios
            .get(
                `https://api.locaided.com/v1/groups/${id}/group_messages`,
                {
                    headers: {
                        "access-token": accessToken,
                        "token-type": tokenType,
                        client: client,
                        expiry: expiry,
                        uid: uid,
                    },
                }
            )
            .then((response) => {
                console.log(response.data.data);
                setMessages((prevMessages) => {
                    const newMessages = response.data.data;
                    if (JSON.stringify(prevMessages) !== JSON.stringify(newMessages)) {
                        return newMessages;
                    }
                    return prevMessages;
                });
                if (isInitialLoad) {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching chats:", error);
                if (isInitialLoad) {
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        fetchMessages(true);
        const interval = setInterval(() => {
            fetchMessages(false);
        }, 5000);

        return () => clearInterval(interval);
    }, [id]);

    const formatDate = (dateString) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // eslint-disable-next-line no-unused-vars
        const [weekday, dayMonthYear] = dateString.split(' ');
        const [day, month, year] = dayMonthYear.split('.');
        const messageDate = new Date(`${year}-${month}-${day}`);

        if (
            messageDate.getDate() === today.getDate() &&
            messageDate.getMonth() === today.getMonth() &&
            messageDate.getFullYear() === today.getFullYear()
        ) {
            return 'Today';
        }

        if (
            messageDate.getDate() === yesterday.getDate() &&
            messageDate.getMonth() === yesterday.getMonth() &&
            messageDate.getFullYear() === yesterday.getFullYear()
        ) {
            return 'Yesterday';
        }

        return dateString;
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleDelete = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/group_messages/${deleteId}`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            fetchMessages();
            handleClose();
            console.log(response, "success");
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const handleClose = () => {
        setOpen(false);
        setDeleteId(null);
    };

    const handleSubmit = async () => {
        const url = `https://api.locaided.com/v1/groups/${id}/group_messages`;
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        const formData = new FormData();
        formData.append("description", description);
        if (image) formData.append("image", image, image.name);
        if (audio) formData.append("audio", audio, audio.name);
        if (video) formData.append("video", video, video.name);

        try {
            // eslint-disable-next-line no-unused-vars
            const response = await axios.post(url, formData, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            }
            );
            console.log(response)
            setDescription("");
            setImage(null);
            setAudio(null);
            setVideo(null);
        } catch (error) {
            console.error(
                "Error sending message:",
                error.response ? error.response.data : error.message
            );
        }
    };

    const renderPreview = () => {
        const hasFiles = image || video || audio;

        return (
            hasFiles && (
                <Stack spacing={2} direction="row" sx={{ marginBottom: "1rem", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    {image && (
                        <img
                            src={URL.createObjectURL(image)}
                            alt="Preview"
                            style={{ maxWidth: "25%", maxHeight: "auto", borderRadius: "8px" }}
                        />
                    )}
                    {video && (
                        <video
                            controls
                            src={URL.createObjectURL(video)}
                            style={{ maxWidth: "25%", maxHeight: "auto", borderRadius: "8px" }}
                        />
                    )}
                    {audio && (
                        <audio controls src={URL.createObjectURL(audio)} style={{ maxWidth: "50%", maxHeight: "2rem", }} />
                    )}
                </Stack>)
        );
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FD445D" }} />
                <Typography variant="h6" style={{ marginTop: "18px" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ height: "88vh", display: "flex", flexDirection: "column", backgroundColor: "#212330", borderRadius: "12px" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" px={2}
                    sx={{
                        backgroundImage: selectedChat.group.group_banner_url?.sharable
                            ? `url(${selectedChat.group.group_banner_url.sharable})`
                            : "none",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        py: selectedChat.group.group_banner_url?.sharable ? "3rem" : "",
                        borderRadius: "8px",
                    }}
                >
                    <Box gap={1} sx={{ display: "flex", alignItems: "center", padding: "0.5rem 0" }}>
                        <IconButton component="span" sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
                            <ArrowIcon width="18px" height="18px" />
                        </IconButton>
                        <Avatar
                            alt={selectedChat.group.name}
                            src={selectedChat.group.group_image_url?.sharable}
                            style={{ width: "32px", height: "32px", }}
                        />
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {selectedChat.group.name}
                        </Typography>
                    </Box>
                    <IconButton component="span" sx={{ color: "#fff" }} onClick={() => navigate(`../group_setting/${id}`)}>
                        <SettingIcon width="22px" height="22px" />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                            width: "0px",
                            height: "0px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "transparent",
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                        },
                    }}
                >
                    {messages.length > 0 ? (
                        messages.map((day, index) => (
                            <React.Fragment key={index}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        textAlign: "center",
                                        color: "#f2f0ef",
                                        fontSize: "12px",
                                    }}
                                >
                                    {formatDate(day.date)}
                                </Typography>
                                {Array.isArray(day.chats_array) &&
                                    day.chats_array
                                        .filter((message) => message && message.id)
                                        .map((message) => (
                                            // <Tooltip title={message.time || ""}>
                                            <Box key={message.id} sx={{
                                                position: "relative",
                                                padding: 0,
                                                margin: 0,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: message.user_id === selectedChat.user.id ? "flex-end" : "flex-start",
                                                "&:hover .toolbar": {
                                                    display: "flex",
                                                },
                                            }}>
                                                {message.user_id === selectedChat.user.id ? "" : (
                                                    <Paper
                                                        sx={{
                                                            p: 0,
                                                            mb: 0.25,
                                                            color: "#0FCD39",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            alignSelf:
                                                                message.user_id === selectedChat.user.id
                                                                    ? "flex-end"
                                                                    : "flex-start",
                                                            maxWidth: "60%",
                                                        }}
                                                        elevation={0}
                                                    >
                                                        <Avatar
                                                            alt={message.user.first_name}
                                                            src={message.user.profile_image_url?.sharable}
                                                            style={{ width: "25px", height: "25px" }}
                                                        />
                                                        <Typography
                                                            variant="caption"
                                                            p={1}
                                                            style={{
                                                                fontWeight: 600,
                                                                fontSize: "11px",
                                                            }}
                                                        >
                                                            {message.user.full_name}
                                                        </Typography>
                                                    </Paper>
                                                )}
                                                <Paper
                                                    sx={{
                                                        p: 0,
                                                        mb: 1,
                                                        bgcolor:
                                                            message.user_id === selectedChat.user.id
                                                                ? "#EAEAEA"
                                                                : "#212330",
                                                        border: "1px solid #E6E6E6",
                                                        borderRadius:
                                                            message.user_id === selectedChat.user.id
                                                                ? "16px 0 16px 16px"
                                                                : "0 16px 16px 16px",
                                                        color:
                                                            message.user_id === selectedChat.user.id
                                                                ? "#000"
                                                                : "#FFF",
                                                        alignSelf:
                                                            message.user_id === selectedChat.user.id
                                                                ? "flex-end"
                                                                : "flex-start",
                                                        maxWidth: "60%",
                                                    }}
                                                >
                                                    {message.image_url?.sharable && (
                                                        <img
                                                            src={message.image_url.sharable}
                                                            alt="Preview"
                                                            style={{
                                                                maxWidth: "100%",
                                                                height: "100%",
                                                                borderRadius: "16px",
                                                                alignSelf: "center",
                                                            }}
                                                        />
                                                    )}
                                                    {message.video_url?.sharable && (
                                                        <video
                                                            controls
                                                            src={message.video_url.sharable}
                                                            alt="Preview"
                                                            style={{
                                                                maxWidth: "100%",
                                                                height: "100%",
                                                                borderRadius: "16px",
                                                                alignSelf: "center",
                                                            }}
                                                        />
                                                    )}
                                                    {message.audio_url?.sharable && (
                                                        <audio
                                                            controls
                                                            src={message.audio_url.sharable}
                                                            style={{
                                                                maxWidth: "100%",
                                                                height: "35px",
                                                                borderRadius: "16px",
                                                                alignSelf: "center",
                                                            }}
                                                        />
                                                    )}
                                                    {!message.description ? (
                                                        null
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            p={1}
                                                            style={{
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {message.description}
                                                        </Typography>
                                                    )}
                                                </Paper>
                                                <Box
                                                    className="toolbar"
                                                    sx={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                        right: message.user_id === selectedChat.user.id ? "60%" : "auto",
                                                        left: message.user_id === selectedChat.user.id ? "auto" : "60%",
                                                        display: "none",
                                                        flexDirection: "row",
                                                        borderRadius: "8px",
                                                        padding: "4px",
                                                    }}
                                                >
                                                    <IconButton size="small" sx={{ color: "#FFF" }}>
                                                        <ReactionIcon width="20px" height="18px" />
                                                    </IconButton>
                                                    <IconButton size="small" sx={{ color: "#FFF" }} onClick={() => handleClickOpen(message.id)}>
                                                        <DeleteIcon width="16px" height="16px" />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            // </Tooltip>
                                        ))}
                            </React.Fragment>
                        ))
                    ) : (
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: "center",
                                color: "#A6A6A6",
                                fontStyle: "italic",
                                mt: 2,
                            }}
                        >
                            No messages yet. Start the conversation!
                        </Typography>
                    )}
                    <div ref={messagesEndRef} />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                    <Stack direction="row">
                        <label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                            />
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <GalleryIcon width="20px" height="20px" />
                            </IconButton>
                        </label>

                        <label>
                            <input
                                type="file"
                                accept="video/*"
                                onChange={handleVideoChange}
                                style={{ display: "none" }}
                            />
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <VideoIcon width="20px" height="20px" />
                            </IconButton>
                        </label>

                        <label>
                            <input
                                type="file"
                                accept="audio/*"
                                onChange={handleAudioChange}
                                style={{ display: "none" }}
                            />
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <MicIcon width="20px" height="20px" />
                            </IconButton>
                        </label>
                    </Stack>
                    <TextField
                        placeholder="Type message..."
                        size="small"
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        minRows={1}
                        maxRows={8}
                        sx={{ mr: 2, borderRadius: "15px", fontSize: "12px" }}
                        InputProps={{
                            sx: { display: "flex", flexDirection: "column", borderRadius: "15px", fontSize: "12px" },
                            startAdornment: (
                                renderPreview()
                            ),
                        }}
                    />
                    <IconButton
                        sx={{
                            color: "#fff",
                            p: 1,
                            backgroundColor: "#ff2557",
                            "&:hover": {
                                backgroundColor: "#ff4b6f",
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#ff83a3",
                            },
                        }}
                        disabled={!description.trim() && !image && !audio && !video}
                        onClick={handleSubmit}
                    >
                        <SendIcon width="15px" height="15px" />
                    </IconButton>

                </Box>
            </Box>
            <Dialog
                open={open}
                sx={{
                    maxWidth: "371px",
                    margin: "auto",
                    "& .MuiPaper-root": {
                        backgroundColor: "#212121",
                        color: "#fff",
                        padding: "15px",
                    },
                    "& .MuiDialogTitle-root": {
                        padding: "5px",
                        textAlign: "center",
                    },
                    "& .MuiDialogContentText-root": {
                        color: "#fff",
                    },
                    "& .MuiDialogContent-root": {
                        padding: "5px",
                    },
                    "& .MuiDialogActions-root": {
                        padding: "5px",
                    },
                    "& .MuiDialog-container": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are You Sure You Want to Delete this Message?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{
                            backgroundColor: "#A6A6A6",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#B8B8B8" },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        sx={{
                            backgroundColor: "#ff2557",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#ff4b6f" },
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GroupChatWindow;
