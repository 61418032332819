import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
  Divider,
  List,
  Avatar,
  CircularProgress,
  Button,
  Slider,
} from '@mui/material';
import { Link, useOutletContext } from "react-router-dom";
import { ReactComponent as SearchIcon } from '../../Assets/search.svg';
import { ReactComponent as FilterIcon } from '../../Assets/filter.svg';
import { ReactComponent as NewsIcon } from '../../Assets/news.svg';
import { ReactComponent as EventsIcon } from '../../Assets/event.svg';
import { ReactComponent as TrafficIcon } from '../../Assets/traffic-light.svg';
import { ReactComponent as HealthIcon } from '../../Assets/health.svg';
import { ReactComponent as TrendingIcon } from '../../Assets/trending.svg';
import { ReactComponent as SOSIcon } from '../../Assets/sos.svg';
import { ReactComponent as AlertIcon } from '../../Assets/alert.svg';
import { ReactComponent as LostIcon } from '../../Assets/lost.svg';
import { ReactComponent as EducationIcon } from '../../Assets/education.svg';
import { ReactComponent as OfferIcon } from '../../Assets/offer.svg';
import { ReactComponent as SearchTabIcon } from '../../Assets/search2.svg';
import { ReactComponent as HotSpotIcon } from '../../Assets/hotspot.svg';

const LiveFeedFilter = () => {
  const { user } = useOutletContext();
  const [radius, setRadius] = useState(10);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trendLocationPosts, setTrendLocationPosts] = useState([]);
  const [loadingTrending, setLoadingTrending] = useState(true);
  const [loadingRadius, setLoadingRadius] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const maxItemsToShow = 4;

  useEffect(() => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get("https://api.locaided.com/v1/users/cities_with_most_records", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        const cities = response.data.data || [];
        setTrendLocationPosts(cities.trending_cities.slice(0, 4));
        setLoadingTrending(false);
      })
      .catch((error) => {
        console.error("Error fetching trending cities:", error);
        setLoadingTrending(false);
      });

    axios
      .get("https://api.locaided.com/v1/users/get_radius", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setRadius(response.data.data.radius || 10);
        setLoadingRadius(false);
      })
      .catch((error) => {
        console.error("Error fetching radius:", error);
        setLoadingRadius(false);
      });
  }, []);

  const fetchChats = () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get("https://api.locaided.com/v1/users/get_all_chats?type=direct", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setChats(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chats:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const tags = [
    'News', 'Traffic', 'Events', 'Hot Spot', 'Search',
    'Offer', 'Education', 'Health', 'Lost & Found', 'Alerts', 'SOS'
  ];

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  const visibleTags = showAll ? selectedTags : selectedTags.slice(0, maxItemsToShow);

  const handleRadiusChange = (e, newValue) => {
    if (newValue !== null) {
      setRadius(newValue);
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .patch(
          "https://api.locaided.com/v1/users/update_radius",
          { radius: newValue },
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((response) => console.log("Radius updated:", response.data))
        .catch((error) => console.error("Error updating radius:", error));
    }
  };

  if (loadingTrending || loadingRadius || loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: 350,
        height: '100vh',
        ml: 1,
        color: '#fff',
      }}
    >

      {/*Search Section */}
      <Box sx={{
        p: 2,
        borderRadius: "12px",
        backgroundColor: '#212330',
      }}>
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search Location"
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon width="16px" height="16px" />
                </IconButton>
              ),
              endAdornment: (
                <IconButton onClick={handleToggleMenu}>
                  <FilterIcon width="20px" height="20px" />
                </IconButton>
              ),
            }}
          />
        </Box>
        {!menuOpen && selectedTags.length > 0 && (
          <Box>
            <Typography variant="body1" sx={{ color: '#F0F1F5', mt: 2 }}>
              Selected Interests
            </Typography>
            <List sx={{ color: '#D7D9E3', mb: -2 }}>
              <Divider color="#2C2F41" />
              {visibleTags.map((tag) => (
                <ListItem
                  key={tag}
                  style={{ padding: '0.25rem' }}
                  sx={{
                    color: '#D7D9E3',
                    borderRadius: '5px',
                  }}
                >
                  {tag === 'News' && <NewsIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Events' && <EventsIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Traffic' && <TrafficIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Health' && <HealthIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Hot Spot' && <HotSpotIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Search' && <SearchTabIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Offer' && <OfferIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Education' && <EducationIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Lost & Found' && <LostIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'Alerts' && <AlertIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  {tag === 'SOS' && <SOSIcon width="20px" height="20px" style={{ marginRight: '0.5rem' }} />}
                  <ListItemText primary={tag} />
                </ListItem>
              ))}
              {selectedTags.length > maxItemsToShow && (
                <ListItem button onClick={handleToggleShowAll} style={{ padding: '0.25rem' }}>
                  <ListItemText
                    primary={showAll ? 'View Less' : 'View All'}
                    primaryTypographyProps={{
                      fontSize: '14px',
                      color: '#D7D9E3',
                      textAlign: 'left',
                    }}
                  />
                </ListItem>
              )}
            </List>
          </Box>
        )}

        {menuOpen && (
          <Grid container spacing={1} my="1rem">
            <Grid item xs={12}>
              <Typography variant="contain" >Select Local Interest</Typography>
              <Divider color="#2C2F41" />
            </Grid>
            {tags.map((tag) => (
              <Grid item xs={6} key={tag}>
                <Button
                  onClick={() => handleTagClick(tag)}
                  sx={{
                    color: '#fff',
                    backgroundColor: selectedTags.includes(tag) ? '#ff2557' : 'transparent',
                    border: `1px solid ${selectedTags.includes(tag) ? '#ff2557' : '#fff'}`,
                    borderRadius: '20px',
                    padding: '0.3rem 0.8rem',
                    fontSize: '0.8rem',
                    textTransform: 'none',
                    width: '100%'
                  }}
                >
                  {tag}
                </Button>
              </Grid>
            ))}
            <Grid item xs={6} textAlign="center">
              <Button
                onClick={handleToggleMenu}
                sx={{ color: '#ff2557', textTransform: 'none', fontSize: '0.8rem' }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>

      {/*Radius Section */}
      <Box sx={{
        p: 2,
        mt: 2,
        borderRadius: "12px",
        backgroundColor: '#212330',
      }}>
        <Typography
          variant="body1"
          gutterBottom
          display="flex"
          alignItems="center"
        >
          Customize Radius
        </Typography>
        <Slider
          value={radius}
          onChange={handleRadiusChange}
          step={1}
          min={3}
          max={user.plan_type === "free" ? 7 : 50}
          valueLabelDisplay="auto"
          sx={{
            color: "#FD445D",
          }}
        />
      </Box>

      {/* Trending Section */}
      <Box sx={{
        p: 2,
        mt: 2,
        borderRadius: "12px",
        backgroundColor: '#212330',
      }}>
        <Box display="flex" alignItems="center" gap={1}><Typography variant="h6" color="#F0F1F5">Trending</Typography><TrendingIcon width="20px" height="20px" /></Box>
        <Typography variant="body2" mb={2} fontSize="11.5px">
          Explore local hotspots and global buzz with real-time updates. See posts from the past 60 minutes to stay in sync with the world's pulse.
        </Typography>
        <Divider color="#2C2F41" />
        {trendLocationPosts && trendLocationPosts.length > 0 ? (<>
          {trendLocationPosts.map((trend, index) => (
            <Box key={index} mb={1}>
              <Typography variant="body2">{trend.name}</Typography>
              <Typography variant="body2" color="#9E9E9E">{`${trend.messages_count} Posts, ${trend.evidences_count} Stories`}</Typography>
            </Box>
          ))}
          <ListItem
            button
            component={Link}
            to="/dashboard/profile"
            sx={{
              padding: "4px",
            }}
          >
            <ListItemText
              primary="View All"
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: "bold"
              }}
            />
          </ListItem> </>) : (
          <Typography variant="body2" color="textSecondary">
            No trending posts available.
          </Typography>
        )}
      </Box>

      {/* Messages Section */}
      <Box sx={{
        p: 2,
        mt: 2,
        borderRadius: "12px",
        backgroundColor: '#212330',
      }}>
        <Typography variant="h6" mb={2} color="#F0F1F5">Messages</Typography>
        <Divider color="#2C2F41" />
        {chats && chats.length > 0 ? (<>
          {chats.map((chat, index) => (
            <ListItem key={index} sx={{ px: 0, my: 1 }}>
              <Avatar alt={chat.message.opposite_user_full_name}
                src={chat.message.opposite_user_profile_image.sharable} sx={{ mr: 2, border: chat.message.read ? "2px solid transparent" : "3px solid #FF2557", }} />
              <ListItemText primary={chat.message.opposite_user_full_name} primaryTypographyProps={{ color: "#D7D9E3" }} />
            </ListItem>
          ))}
          <ListItem
            button
            component={Link}
            to="/dashboard/chats"
            sx={{
              padding: "4px",
            }}
          >
            <ListItemText
              primary="View All"
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: "bold"
              }}
            />
          </ListItem>
        </>) : (
          <Typography variant="body2" color="textSecondary">
            No Chats available.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LiveFeedFilter;
