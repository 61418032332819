import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  InputAdornment,
  InputBase,
  IconButton,
  Paper,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MutedContacts = () => {
  const [mutedContacts, setMutedContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const navigate = useNavigate();

  const fetchMuted = () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get("https://api.locaided.com/v1/users/muted_users", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setMutedContacts(
          (response.data.data || []).filter((contact) => contact !== null)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching radius:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchMuted();
  }, []);

  const handleUnmute = async (id) => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    try {
      const response = await axios.put(
        `https://api.locaided.com/v1/users/${id}/unmute`,
        {},
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      );

      fetchMuted();
      setSeverity("success");
      setMessage(response.data.message);
      setOpen(true);
    } catch (error) {
      setSeverity("error");
      setMessage("Failed to unmute the user. Please try again.");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0 " }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <IconButton onClick={() => navigate("/dashboard/contacts")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" ml={2}>
          Muted Contacts
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          margin: "10px auto",
          borderRadius: "25px",
        }}
      >
        <InputBase
          style={{ marginLeft: 8, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Paper>
      <Typography variant="subtitle1" mt={3} mb={1}>
        Muted Contacts
      </Typography>

      <List>
        {mutedContacts.map((contact, index) => (
          <ListItem
            key={index}
            sx={{
              mt: 1,
              mb: 1,
            }}
            secondaryAction={
              <Button
                sx={{
                  color: "#ff2557",
                  border: "1px solid #ff2557",
                  borderRadius: 10,
                  fontSize: "12px",
                }}
                onClick={() => handleUnmute(contact.id)}
              >
                Unmute
              </Button>
            }
          >
            <ListItemAvatar>
              <Avatar src={contact.profile_image_url.sharable} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {contact.full_name}
                </Typography>
              }
              secondary={
                <Typography variant="body2" sx={{ fontSize: "11px" }}>
                  {contact.username}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MutedContacts;
